export interface ZipCheckerResponse {
    success: boolean
    zipcodeFound: boolean
    purposes?: string[]
}

const getUrl = (environment: string) => {
    switch (environment) {
        case 'dev':
            return 'https://tx7ld4w217.execute-api.us-east-1.amazonaws.com/prod?'
        case 'test':
            return 'https://tx7ld4w217.execute-api.us-east-1.amazonaws.com/prod?'
        case 'prod':
            return 'https://tx7ld4w217.execute-api.us-east-1.amazonaws.com/prod?'
        default:
            return 'https://tx7ld4w217.execute-api.us-east-1.amazonaws.com/prod?'
    }
}
const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'dev':
            return process.env.REACT_APP_DEV_ZIP_CHECKER_API_KEY ? process.env.REACT_APP_DEV_ZIP_CHECKER_API_KEY : ''
        case 'test':
            return process.env.REACT_APP_TEST_ZIP_CHECKER_API_KEY ? process.env.REACT_APP_TEST_ZIP_CHECKER_API_KEY : ''
        case 'prod':
            return process.env.REACT_APP_PROD_ZIP_CHECKER_API_KEY ? process.env.REACT_APP_PROD_ZIP_CHECKER_API_KEY : ''
        default:
            return process.env.REACT_APP_PROD_ZIP_CHECKER_API_KEY ? process.env.REACT_APP_PROD_ZIP_CHECKER_API_KEY : ''
    }
}

const checkZip = async (request: { config: any; formData: any; body: any }) => {
    const { config, formData } = request
    const tenant = formData.topServiceableCompany
    const zip = formData.addressZip
    const purpose = request?.body?.purpose || 'highspeed_offer'
    const params = new URLSearchParams({
        purpose,
        tenant,
        zip,
    })

    const API_KEY = getApiKey(config.environment.zipChecker)
    const ENDPOINT = getUrl(config.environment.zipChecker) + params

    const result = await fetch(ENDPOINT, {
        headers: {
            'x-api-key': API_KEY,
            Authorization: 'freeconnect',
        },
    })
    const data: ZipCheckerResponse = await result.json()
    return data
}

const endpoints: { [key: string]: Function } = {
    main: checkZip,
}

const checkZipMain = async (endpoint: string, request: { config: any; formData: any; body?: any }) => {
    return endpoints[endpoint](request)
}

export default checkZipMain
