// Packages
import React, { useContext, useEffect, useRef } from 'react'
// UI
import Loading from '../../ui/LoadingBlocker'
// Contexts
import { FormDataContext } from '../../contexts/FormData'
import { ConfigContext } from '../../contexts/Config'
import { CopyContext } from '../../contexts/Copy'
import { DataFunnelContext } from '../../contexts/DataFunnel'
// Helpers
import makeBackendRequest from '../../helpers/backend'
import { ZipCheckerResponse } from '../../helpers/backend/checkZip'

function ServiceabilityCheckBlockerBasic({ onNext }: any) {
    // Contexts
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [config]: any = useContext(ConfigContext)
    const [copy]: any = useContext(CopyContext)
    const [dataFunnel, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    // Refs
    const useEffectBlocker = useRef(false)

    useEffect(() => {
        if (useEffectBlocker.current) return

        // add a check here to only set if not set before
        if (!dataFunnel?.timers?.serviceabilityBlockerTimer) {
            dataFunnelDispatch({
                type: 'START_MERGED_TIMER',
                payload: { name: `serviceabilityBlockerTimer` },
            })
        }

        if (formData?.topServiceableCompany && formData?.existingProvider) {
            formDataDispatch({
                type: 'SET_FIELD',
                payload: {
                    name: 'existingCustomer',
                    value: formData?.topServiceableCompany.toLowerCase() === formData?.existingProvider.toLowerCase(),
                },
            })
        }

        if (formData?.topServiceableCompany) {
            if (formData?.topServiceableCompany === 'optimum') {
                // Optimum specific offers for east vs west coast
                makeBackendRequest(
                    { stack: 'checkZipMain', endpoint: 'main' },
                    {
                        config,
                        formData,
                        formDataDispatch,
                        additionalCallStack: 'ibexServiceabilityCheck',
                        makeBackendRequest,
                        body: { purpose: 'west_coast_zips,east_coast_zips' },
                    }
                ).then((data: ZipCheckerResponse) => {
                    const { purposes } = data
                    const zipSide = purposes?.[0].split('_')[0]
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'zipCodeCoast', value: zipSide },
                    })
                })
            }
            if (copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]?.highSpeedOffers) {
                makeBackendRequest(
                    { stack: 'metaConversion', endpoint: 'inApp' },
                    {
                        config,
                        formData,
                    }
                )
                makeBackendRequest(
                    { stack: 'checkZipMain', endpoint: 'main' },
                    {
                        config,
                        formData,
                        formDataDispatch,
                        additionalCallStack: 'ibexServiceabilityCheck',
                        makeBackendRequest,
                    }
                )
                    .then((data: ZipCheckerResponse) => {
                        const { zipcodeFound } = data
                        formDataDispatch({
                            type: 'SET_FIELD',
                            payload: { name: 'highspeedElegible', value: zipcodeFound },
                        })
                    })
                    .then(() => {
                        onNext()
                        useEffectBlocker.current = true
                    })
            } else {
                makeBackendRequest(
                    { stack: 'metaConversion', endpoint: 'inApp' },
                    {
                        config,
                        formData,
                    }
                )
                onNext()
                useEffectBlocker.current = true
            }
            dataFunnelDispatch({
                type: 'END_MERGED_TIMER',
                payload: { name: `serviceabilityBlockerTimer` },
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.topServiceableCompany])

    return <Loading text="This may take up to 30 secs..." useLoadingBar />
}

export default ServiceabilityCheckBlockerBasic
