/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useState, useEffect, useRef } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import AddressField from '../../ui/AddressField'
import Header from '../../ui/Header'
import Footer from '../../ui/Footer'
// Helpers
import Checkbox from '../../ui/Checkbox'
import {
    requireNonEmptyValue,
    smartyValidation,
    requireResidentialAddress,
    requireValidAddressLine2,
    requireNotPoBox,
    requireValidAddress,
    requireAddressLine2IfAddressHasUnits,
} from '../../helpers/validation/unitFunctions'
import { parseCopy } from '../../helpers/parseCopy'
// Components
import ConditionalRender from '../../components/ConditionalRender'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { FormDataContext } from '../../contexts/FormData'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { ValidationContext } from '../../contexts/Validation'
import { StepsContext } from '../../contexts/Steps'
import StepperButtonContainer from '../../ui/StepperButtonContainer'
import Button from '../../ui/Button'

// function AddressBasic({ step, buttons, progressBar, onNext, onPrev }: any) {
function AddressBasic({ step, progressBar, onNext, onPrev }: any) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [, ValidationDispatch]: any = useContext(ValidationContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    // States
    const [displayShippingAddress, setDisplayShippingAddress] = useState(false)
    // Refs
    const topOfStep: any = useRef(null)

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    return (
        <Page>
            <div ref={topOfStep} />
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="addressBasicHeader">
                            <StyleCopy>{parseCopy(copy?.steps?.addressBasic?.title, { config, formData })}</StyleCopy>
                        </Heading>
                        <SubHeading name="addressBasicSubHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.addressBasic?.subHeader, { config, formData })}
                            </StyleCopy>
                        </SubHeading>
                        <AddressField
                            step={step}
                            name="address"
                            validationFunctions={[
                                async (parameters: any) => {
                                    return smartyValidation(
                                        { endpoint: '/', request: { config, formData } },
                                        [
                                            requireNotPoBox,
                                            requireValidAddress,
                                            requireResidentialAddress,
                                            requireAddressLine2IfAddressHasUnits,
                                            requireValidAddressLine2,
                                        ],
                                        parameters
                                    )
                                },
                            ]}
                            street={{
                                validation: [requireNonEmptyValue],
                                formDataKey: 'addressStreet',
                                name: 'addressStreet',
                                subLabelCopy: copy?.steps?.addressBasic?.addressFields?.street?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.street?.subLabel, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                                initialValue: formData.addressStreet,
                                onChange: (value: any) => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'addressStreet', value },
                                    })
                                },
                                labelCopy: (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.street?.label, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                            }}
                            secondary={{
                                validation: [],
                                formDataKey: 'addressSecondary',
                                name: 'addressSecondary',
                                subLabelCopy: copy?.steps?.addressBasic?.addressFields?.line2?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.line2?.subLabel, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                                initialValue: formData.addressSecondary,
                                onChange: (value: any) => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'addressSecondary', value },
                                    })
                                },
                                labelCopy: (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.line2?.label, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                            }}
                            city={{
                                validation: [requireNonEmptyValue],
                                formDataKey: 'addressCity',
                                name: 'addressCity',
                                subLabelCopy: copy?.steps?.addressBasic?.addressFields?.city?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.city?.subLabel, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                                initialValue: formData.addressCity,
                                onChange: (value: any) => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'addressCity', value },
                                    })
                                },
                                labelCopy: (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.city?.label, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                            }}
                            state={{
                                validation: [requireNonEmptyValue],
                                formDataKey: 'addressState',
                                name: 'addressState',
                                subLabelCopy: copy?.steps?.addressBasic?.addressFields?.state?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.state?.subLabel, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                                initialValue: formData.addressState,
                                onChange: (value: any) => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'addressState', value },
                                    })
                                },
                                labelCopy: (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.state?.label, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                            }}
                            zip={{
                                validation: [requireNonEmptyValue],
                                formDataKey: 'addressZip',
                                name: 'addressZip',
                                subLabelCopy: copy?.steps?.addressBasic?.addressFields?.zipCode?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.zipCode?.subLabel, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                                initialValue: formData.addressZip,
                                onChange: (value: any) => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'addressZip', value },
                                    })
                                },
                                labelCopy: (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.addressBasic?.addressFields?.zipCode?.label, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                ),
                            }}
                        />
                        <Checkbox
                            name="addressBasicShippingAddress"
                            onChange={(value: boolean) => {
                                setDisplayShippingAddress(value)
                                ValidationDispatch({
                                    type: 'REMOVE_FIELDS',
                                    payload: {
                                        names: [
                                            'shippingAddress',
                                            'shippingAddressStreet',
                                            'shippingAddressSecondary',
                                            'shippingAddressState',
                                            'shippingAddressCity',
                                            'shippingAddressZip',
                                        ],
                                        step,
                                    },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.addressBasic?.differentShipping, { config, formData })}
                            </StyleCopy>
                        </Checkbox>
                        <ConditionalRender condition={displayShippingAddress}>
                            <AddressField
                                step={step}
                                name="shippingAddress"
                                validationFunctions={[
                                    async (parameters: any) => {
                                        return smartyValidation(
                                            { endpoint: '/', request: { config, formData } },
                                            [
                                                requireValidAddress,
                                                requireResidentialAddress,
                                                requireAddressLine2IfAddressHasUnits,
                                                requireValidAddressLine2,
                                            ],
                                            parameters
                                        )
                                    },
                                ]}
                                street={{
                                    validation: [requireNonEmptyValue],
                                    formDataKey: 'shippingAddressStreet',
                                    name: 'shippingAddressStreet',
                                    subLabelCopy: copy?.steps?.addressBasic?.shippingAddressFields?.street
                                        ?.subLabel && (
                                        <StyleCopy>
                                            {parseCopy(
                                                copy?.steps?.addressBasic?.shippingAddressFields?.street?.subLabel,
                                                {
                                                    config,
                                                    formData,
                                                }
                                            )}
                                        </StyleCopy>
                                    ),
                                    initialValue: formData.shippingAddress,
                                    onChange: (value: any) => {
                                        formDataDispatch({
                                            type: 'SET_FIELD',
                                            payload: { name: 'shippingAddressStreet', value },
                                        })
                                    },
                                    labelCopy: (
                                        <StyleCopy>
                                            {parseCopy(
                                                copy?.steps?.addressBasic?.shippingAddressFields?.street?.label,
                                                {
                                                    config,
                                                    formData,
                                                }
                                            )}
                                        </StyleCopy>
                                    ),
                                }}
                                secondary={{
                                    validation: [],
                                    formDataKey: 'shippingAddressSecondary',
                                    name: 'shippingAddressSecondary',
                                    subLabelCopy: copy?.steps?.addressBasic?.shippingAddressFields?.line2?.subLabel && (
                                        <StyleCopy>
                                            {parseCopy(
                                                copy?.steps?.addressBasic?.shippingAddressFields?.line2?.subLabel,
                                                {
                                                    config,
                                                    formData,
                                                }
                                            )}
                                        </StyleCopy>
                                    ),
                                    initialValue: formData.shippingAddressSecondary,
                                    onChange: (value: any) => {
                                        formDataDispatch({
                                            type: 'SET_FIELD',
                                            payload: { name: 'shippingAddressSecondary', value },
                                        })
                                    },
                                    labelCopy: (
                                        <StyleCopy>
                                            {parseCopy(copy?.steps?.addressBasic?.shippingAddressFields?.line2?.label, {
                                                config,
                                                formData,
                                            })}
                                        </StyleCopy>
                                    ),
                                }}
                                city={{
                                    validation: [requireNonEmptyValue],
                                    formDataKey: 'shippingAddressCity',
                                    name: 'shippingAddressCity',
                                    subLabelCopy: copy?.steps?.addressBasic?.shippingAddressFields?.city?.subLabel && (
                                        <StyleCopy>
                                            {parseCopy(
                                                copy?.steps?.addressBasic?.shippingAddressFields?.city?.subLabel,
                                                {
                                                    config,
                                                    formData,
                                                }
                                            )}
                                        </StyleCopy>
                                    ),
                                    initialValue: formData.shippingAddressCity,
                                    onChange: (value: any) => {
                                        formDataDispatch({
                                            type: 'SET_FIELD',
                                            payload: { name: 'shippingAddressCity', value },
                                        })
                                    },
                                    labelCopy: (
                                        <StyleCopy>
                                            {parseCopy(copy?.steps?.addressBasic?.shippingAddressFields?.city?.label, {
                                                config,
                                                formData,
                                            })}
                                        </StyleCopy>
                                    ),
                                }}
                                state={{
                                    validation: [requireNonEmptyValue],
                                    formDataKey: 'shippingAddressState',
                                    name: 'shippingAddressState',
                                    subLabelCopy: copy?.steps?.addressBasic?.shippingAddressFields?.state?.subLabel && (
                                        <StyleCopy>
                                            {parseCopy(
                                                copy?.steps?.addressBasic?.shippingAddressFields?.state?.subLabel,
                                                {
                                                    config,
                                                    formData,
                                                }
                                            )}
                                        </StyleCopy>
                                    ),
                                    initialValue: formData.shippingAddressState,
                                    onChange: (value: any) => {
                                        formDataDispatch({
                                            type: 'SET_FIELD',
                                            payload: { name: 'shippingAddressState', value },
                                        })
                                    },
                                    labelCopy: (
                                        <StyleCopy>
                                            {parseCopy(copy?.steps?.addressBasic?.addressFields?.state?.label, {
                                                config,
                                                formData,
                                            })}
                                        </StyleCopy>
                                    ),
                                }}
                                zip={{
                                    validation: [requireNonEmptyValue],
                                    formDataKey: 'shippingAddressZip',
                                    name: 'shippingAddressZip',
                                    subLabelCopy: copy?.steps?.addressBasic?.shippingAddressFields?.zipCode
                                        ?.subLabel && (
                                        <StyleCopy>
                                            {parseCopy(
                                                copy?.steps?.addressBasic?.shippingAddressFields?.zipCode?.subLabel,
                                                {
                                                    config,
                                                    formData,
                                                }
                                            )}
                                        </StyleCopy>
                                    ),
                                    initialValue: formData.shippingAddressZip,
                                    onChange: (value: any) => {
                                        formDataDispatch({
                                            type: 'SET_FIELD',
                                            payload: { name: 'shippingAddressZip', value },
                                        })
                                    },
                                    labelCopy: (
                                        <StyleCopy>
                                            {parseCopy(
                                                copy?.steps?.addressBasic?.shippingAddressFields?.zipCode?.label,
                                                {
                                                    config,
                                                    formData,
                                                }
                                            )}
                                        </StyleCopy>
                                    ),
                                }}
                            />
                        </ConditionalRender>
                        {/* {buttons} */}
                        <StepperButtonContainer>
                            <Button
                                type="fill"
                                name="addressBasicNoTempAddressNext"
                                className={
                                    // eslint-disable-next-line no-nested-ternary
                                    config?.advertisingPlatform === 'google'
                                        ? 'dd-gtm'
                                        : config?.advertisingPlatform === 'facebook'
                                          ? 'fb-gtm-pixel'
                                          : ''
                                }
                                onClick={async () => {
                                    topOfStep.current.scrollIntoView({
                                        behavior: 'smooth',
                                    })
                                    await onNext()
                                }}
                            >
                                <StyleCopy
                                    id={
                                        // eslint-disable-next-line no-nested-ternary
                                        config?.advertisingPlatform === 'google'
                                            ? 'dd-gtm'
                                            : config?.advertisingPlatform === 'facebook'
                                              ? 'fb-gtm-pixel'
                                              : ''
                                    }
                                >
                                    {steps.currentStep === steps.main.length - 1
                                        ? parseCopy(copy?.steps?.addressBasic?.submitButton, {
                                              config,
                                              formData,
                                              copy: copy?.steps?.existingCustomerQuestionBasic,
                                          })
                                        : parseCopy(copy?.steps?.addressBasic?.nextButton, {
                                              config,
                                              formData,
                                              copy: copy?.steps?.existingCustomerQuestionBasic,
                                          })}
                                </StyleCopy>
                            </Button>
                            <Button
                                type="ghost"
                                name="addressBasicNoTempAddressPrev"
                                onClick={async () => {
                                    await onPrev()
                                }}
                            >
                                {copy?.steps?.addressBasic?.previousButton}
                            </Button>
                        </StepperButtonContainer>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}

export default AddressBasic
