/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'

function Page({ className = '', children }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [config]: any = useContext(ConfigContext)

    if (Object.keys(styles).length === 0) {
        return null
    }

    if (config?.tenant === 'easyconnect') {
        return (
            <div
                className={styles.twMerge(
                    styles?.ui?.Page?.mainContainer?.mobile,
                    styles?.ui?.Page?.mainContainer?.tablet,
                    styles?.ui?.Page?.mainContainer?.desktop,
                    className
                )}
            >
                <div
                    className={styles.twMerge(
                        styles?.ui?.Page?.background?.mobile,
                        styles?.ui?.Page?.background?.tablet,
                        styles?.ui?.Page?.background?.desktop,
                        className
                    )}
                />
                <div
                    className={styles.twMerge(
                        styles?.ui?.Page?.cardContainer?.mobile,
                        styles?.ui?.Page?.cardContainer?.tablet,
                        styles?.ui?.Page?.cardContainer?.desktop,
                        className
                    )}
                >
                    {children}
                </div>
            </div>
        )
    }
    return (
        <div
            className={styles.twMerge(
                styles?.ui?.Page?.main?.mobile,
                styles?.ui?.Page?.main?.tablet,
                styles?.ui?.Page?.main?.desktop,
                className
            )}
        >
            {children}
        </div>
    )
}

export default Page
