/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useRef } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import DisplayGenericOffer from '../../ui/DisplayGenericOffer'
import Button from '../../ui/Button'
import Header from '../../ui/Header'
import Footer from '../../ui/Footer'
import BodyText4 from '../../ui/BodyText4'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { StylesContext } from '../../contexts/Styles'
import { StepsContext } from '../../contexts/Steps'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import makeBackendRequest from '../../helpers/backend'
import ConditionalRender from '../../components/ConditionalRender'

function SuccessAllProvider({ onNext, progressBar }: any) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)
    const [styles]: any = useContext(StylesContext)
    const useEffectBlocker = useRef(false)

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    useEffect(() => {
        if (useEffectBlocker.current) return

        makeBackendRequest(
            { stack: 'ses', endpoint: 'sendToSqs' },
            {
                config,
                formData,
                body: {
                    use_case: 'generic_app_received',
                    recipients_email: formData.email,
                    cnxId: formData.connexID,
                },
            }
        )
        useEffectBlocker.current = true

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="successAllProviderHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.successAllProvider?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.successAllProvider,
                                })}
                            </StyleCopy>
                        </Heading>
                        <SubHeading name="successAllProviderSubHeader">
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.successAllProvider?.offer?.[formData?.topServiceableCompany]
                                        ?.customSubHeader
                                        ? copy.steps.successAllProvider.offer[formData?.topServiceableCompany]
                                              .customSubHeader
                                        : copy?.steps?.successAllProvider?.subHeader,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.successAllProvider,
                                    }
                                )}
                            </StyleCopy>
                        </SubHeading>
                        <DisplayGenericOffer
                            topServiceableCompany={formData.topServiceableCompany}
                            titleCopy={
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.successAllProvider?.offer?.[formData?.topServiceableCompany]
                                            ?.title,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.successAllProvider,
                                        }
                                    )}
                                </StyleCopy>
                            }
                            price={
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.successAllProvider?.offer?.[formData?.topServiceableCompany]
                                            ?.price,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.successAllProvider,
                                        }
                                    )}
                                </StyleCopy>
                            }
                            timeUnit={
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.successAllProvider?.offer?.[formData?.topServiceableCompany]
                                            ?.timeUnit,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.successAllProvider,
                                        }
                                    )}
                                </StyleCopy>
                            }
                        />
                        <ConditionalRender condition={config?.formName !== 'offersFirst'}>
                            <Button
                                type="fill"
                                className={styles.twMerge(
                                    styles?.steps?.successAllProvider?.Button?.mobile,
                                    styles?.steps?.successAllProvider?.Button?.tablet,
                                    styles?.steps?.successAllProvider?.Button?.desktop
                                )}
                                name="successAllProviderCallNow"
                                onClick={() => {
                                    window.open(
                                        `tel:+1${parseCopy(
                                            copy?.steps?.successAllProvider?.callNowButton?.phoneNumber,
                                            {
                                                config,
                                                formData,
                                                copy: copy?.steps?.successAllProvider,
                                            }
                                        )}`
                                    )
                                }}
                            >
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.successAllProvider?.callNowButton?.text, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.successAllProvider,
                                    })}
                                </StyleCopy>
                            </Button>
                        </ConditionalRender>
                        <ConditionalRender condition={config?.formName !== 'offersFirst'}>
                            <BodyText4
                                name="successAllProviderFooter"
                                className={config?.tenant === 'freeconnect' ? '' : 'mt-[24px]'}
                            >
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.successAllProvider?.footer, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.successAllProvider,
                                    })}
                                </StyleCopy>
                            </BodyText4>
                        </ConditionalRender>
                    </Main>
                </div>
                <ConditionalRender condition={config?.formName === 'offersFirst'} falseReturn={<Footer />}>
                    <div className="flex flex-col justify-center content-center">
                        <div className="w-full flex justify-center">
                            <Button
                                type="fill"
                                className={styles.twMerge(
                                    styles?.steps?.successAllProvider?.offersFirstButton?.mobile,
                                    styles?.steps?.successAllProvider?.offersFirstButton?.tablet,
                                    styles?.steps?.successAllProvider?.offersFirstButton?.desktop
                                )}
                                name="successAllProviderCallNow"
                                onClick={() => {
                                    onNext()
                                }}
                            >
                                <StyleCopy>Get Connected</StyleCopy>
                            </Button>
                        </div>
                        <Footer />
                    </div>
                </ConditionalRender>
            </Container>
        </Page>
    )
}

export default SuccessAllProvider
