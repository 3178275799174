const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000'
        case 'dev':
            return 'https://0mzop1juh9.execute-api.us-east-1.amazonaws.com/prod'
        case 'test':
            return 'https://dy9aq4ubq5.execute-api.us-east-1.amazonaws.com/prod'
        default:
            return 'https://7uwu6bnxtc.execute-api.us-east-1.amazonaws.com/prod'
    }
}

const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_CONVERSION_API_KEY ? process.env.REACT_APP_DEV_CONVERSION_API_KEY : ''
        case 'test':
            return process.env.REACT_APP_TESTING_CONVERSION_API_KEY
                ? process.env.REACT_APP_TESTING_CONVERSION_API_KEY
                : ''
        default:
            return process.env.REACT_APP_PROD_CONVERSION_API_KEY ? process.env.REACT_APP_PROD_CONVERSION_API_KEY : ''
    }
}

const inApp = async (request: { config: any; formData: any; body?: any }) => {
    if (request?.config?.devMode === true) return {}

    return fetch(`${getUrl(request.config.environment.metaConversion)}/in-app`, {
        method: 'POST',
        headers: {
            'x-api-key': getApiKey(request.config.environment.metaConversion),
            Authorization: 'combo',
        },
        body: JSON.stringify({
            firstname: request?.formData?.firstName,
            lastname: request?.formData?.lastName,
            email: request?.formData?.email,
            phone: request?.formData?.phoneNumber,
            city: request?.formData?.addressCity,
            state: request?.formData?.addressState,
            zipcode: request?.formData?.addressZip,
        }),
    })
}

const endpoints: { [key: string]: Function } = {
    inApp,
}

const metaConversion = async (endpoint: string, request: { config: any; formData: any }) => {
    return endpoints[endpoint](request)
}

export default metaConversion
