const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000'
        case 'dev':
            return 'https://lboyueuzli.execute-api.us-east-1.amazonaws.com/prod'
        case 'test':
            return 'https://g4kb6ds806.execute-api.us-east-1.amazonaws.com/prod'
        default:
            return 'https://fpqo866loe.execute-api.us-east-1.amazonaws.com/prod'
    }
}

const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_CNX1_KEY ? process.env.REACT_APP_DEV_CNX1_KEY : ''
        case 'test':
            return process.env.REACT_APP_TEST_CNX1_KEY ? process.env.REACT_APP_TEST_CNX1_KEY : ''
        default:
            return process.env.REACT_APP_PROD_CNX1_KEY ? process.env.REACT_APP_PROD_CNX1_KEY : ''
    }
}

const processDemoform = (request: { config: any; formData: any; queryParams?: any; cnxFifoQueue?: any }) => {
    if (request?.config?.devMode === true)
        return new Promise((resolve) => {
            resolve({
                json: () => {
                    return new Promise((resolveJson) => {
                        resolveJson({})
                    })
                },
            })
        })

    let fundleBundleString = ''
    if (request?.formData?.fundleBundleDirectTv || request?.formData?.fundleBundleVivint) {
        fundleBundleString = request?.formData?.fundleBundleDirectTv ? 'DirectTv' : 'Vivint'
    }
    if (request?.formData?.fundleBundleDirectTv && request?.formData?.fundleBundleVivint) {
        fundleBundleString = 'DirectTv and Vivint'
    }

    const body: any = {
        contactDataID: request?.formData?.connexOneLeadId ? request.formData.connexOneLeadId : undefined,
        aceID: request?.config?.aceId ? request.config.aceId : '',
        smsUuid: request?.formData?.uuid ? request?.formData?.uuid : '',
        // chatID: userId,
        // chatURL: formData.chatURL,
        firstName: request?.formData?.firstName,
        middleName: request?.formData?.middleName,
        lastName: request?.formData?.lastName,
        dateOfBirth: request?.formData?.birthDate,
        // ssnLast4:
        //     formData.topServiceableCompany === 'optimum' && formData.isSsnSplit ? formData.ssn.slice(-4) : formData.ssn,
        phoneNumber: request?.formData?.phoneNumber,
        email: request?.formData?.email,
        addressLn1: request?.formData?.addressStreet,
        addressLn2: request?.formData?.addressSecondary,
        city: request?.formData?.addressCity,
        state: request?.formData?.addressState,
        zipCode: request?.formData?.addressZip,
        shippingLn1: request?.formData?.shippingAddressStreet,
        shippingLn2: request?.formData?.shippingAddressSecondary,
        shippingCity: request?.formData?.shippingAddressCity,
        shippingState: request?.formData?.shippingAddressState,
        shippingZipcode: request?.formData?.shippingAddressZip,
        addressType: request?.formData?.addressType,
        isAddressValidated: true,
        // isSsnLead: formData.topServiceableCompany === 'optimum' && formData.isSsnSplit,
        isCheckForTribal: null,
        isCrosschecked: null,
        isExistingCustomer: request?.formData?.existingCustomer,
        isSmsConsent: request?.formData?.smsToContact ? request.formData.smsToContact : false,
        isCellConsent: request?.formData?.consentToContact ? request.formData.consentToContact : false,
        isPrimaryEmailConsent: request?.formData?.consentToContact ? request.formData.consentToContact : false,
        isMarketingEmailConsent: request?.formData?.marketingEmailConsent
            ? request.formData.marketingEmailConsent
            : false,
        // isPreInstall: formData.preinstallDate ? true : null,
        // installTimeWindow: formData.installTimeFrame,
        enrollmentID: '',
        orderNumber: '',
        // selectedProgram: formData?.programs?.[0]?.program_code,
        // nvAppID: formData.acpId,
        offerSold: '',
        selectedOffer: request?.formData?.selectedPlan ? request.formData.selectedPlan : '',
        selectedBundle: fundleBundleString,
        selectedProvider: request?.formData?.topServiceableCompany,
        demoFormTestName: request?.formData?.newOrderOfferSplit ? 'newOfferOrder' : '',
        userLeadFormName: request?.config?.formName,
        // frontierOffer: formData.frontierOffer,
        // installDate: formData.preinstallDate
        //     ? moment(formData.preinstallDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
        //     : null,
        targetedProvider: request?.config?.targetedProvider,
        eligibleProviders: request?.formData?.eligibleProvidersString,
        serviceabilityCheckResults: request?.formData?.topServiceableCompany,
        serviceProviderSold: '',
        outsourcedCloser: '',
        adSetID: '',
        adID: '',
        // adName: request?.formData?.sponsor_id,
        adAccountID: '',
        adAccountName: '',
        campaignID: '',
        campaignType: request?.config?.campaignType,
        advertisingPlatform: request?.config?.advertisingPlatform,
        leadReference: request?.config?.leadReference,
        internalLeadReference: request?.config?.internalLeadReference,
        sourceReference: request?.config?.sourceReference,
        trustedFormCertURL: '',
        // eslint-disable-next-line no-unneeded-ternary
        isDemoformComplete: request?.formData?.isDemoformComplete ? true : false,
        // isAutoInstallDate: formData.isAutoSelectPageShown ? formData.isAutoSelectPageShown : false,
        facebookClickId: request?.queryParams?.fbclid,
        // isComLink: config?.isComLink ? true : false,
        dataListID: request?.config?.dataListId,
        isLabelRequested: request?.formData?.isBroadbandLabelRequested,
        isDemoformSubmitted: request?.formData?.isOrderSubmitted,
        ssmCode: request?.queryParams?.formId,
        eligible_provider_primary_count: request?.formData?.primaryProviderCount,
        eligible_provider_satellite_count: request?.formData?.satelliteProviderCount,
        customerAgreedPrice: request?.formData?.customerAgreedPrice,
        customerFirstBill: request?.formData?.customerFirstBill,
        lifelineSelected: request?.formData?.isLifelineDiscount ? 'Selected' : undefined,
        tenant: request?.config?.tenant,
    }

    if (request?.formData?.topServiceableCompany === 'unserviceable') {
        body.eligibleProviders = 'Unknown'
    }

    if (request?.formData?.hasExistingAcp !== undefined && request?.formData?.hasExistingAcp !== null) {
        body.itmLeadType = request.formData.hasExistingAcp ? 'transfer' : 'nonAcpAcquisition'
    }

    // Add to a queue Store for Fifo
    if (request?.cnxFifoQueue) {
        request?.cnxFifoQueue({
            type: 'SET_CALL_IN_QUEUE',
            payload: body,
        })
    }
    return new Promise((resolve) => {
        resolve({
            json: () => {
                return new Promise((resolveJson) => {
                    resolveJson({})
                })
            },
        })
    })

    // return fetch(`${getUrl(request?.config?.environment?.connexOne)}/process-demoform`, {
    //     method: 'POST',
    //     body: JSON.stringify(body),
    //     headers: {
    //         'x-api-key': getApiKey(request?.config?.environment?.connexOne),
    //         company: request?.formData.topServiceableCompany,
    //         Authorization: request?.formData.topServiceableCompany,
    //     },
    // })
}

const updateContactSes = async (request: { config: any; formData: any; queryParams?: any }) => {
    if (request?.config?.devMode === true) return {}

    const body: any = {
        id: request?.queryParams?.id ? request.queryParams.id : undefined,
        isMarketingEmailConsent: request?.formData?.marketingEmailConsent,
    }

    return fetch(`${getUrl(request?.config?.environment?.connexOne)}/update-contact`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'x-api-key': getApiKey(request?.config?.environment?.connexOne),
            company: request?.formData.topServiceableCompany,
            Authorization: request?.formData.topServiceableCompany,
        },
    })
}

const endpoints: { [key: string]: Function } = {
    processDemoform,
    updateContactSes,
}

const connexOne = async (
    endpoint: string,
    request: { config: any; formData: any; queryParams?: any; cnxFifoQueue?: any }
) => {
    return endpoints[endpoint](request)
}

export default connexOne
