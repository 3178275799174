/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
// libraries
import React, { useContext, useEffect, useState } from 'react'
// ui
import Switch from './Switch'
import TitleText2 from './TitleText2'
import BodyText4 from './BodyText4'
// functional components
import StyleCopy from '../components/StyleCopy'
import ConditionalRender from '../components/ConditionalRender'
// Context
import { PopupContext } from '../contexts/Popup'
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
// Helpers
import getConfigData from '../helpers/config/getConfigData'
import BodyText2 from './BodyText2'

interface Properties {
    title?: string
    description?: string
    onCheck?: any
    toggleLeft?: boolean
    infoPopup?: string
    imageString?: string
}

function ToggleCard({ title, description, onCheck, toggleLeft, infoPopup, imageString }: Properties) {
    const [, popupDispatch]: any = useContext(PopupContext)
    const [styles]: any = useContext(StylesContext)
    const [config]: any = useContext(ConfigContext)
    const [queryParams]: any = useContext(QueryParamsContext)

    const [checked, setChecked] = useState(false)
    const [image, setImage]: any = useState({})

    useEffect(() => {
        if (imageString) {
            getConfigData('images.json', config.tenant, {
                formName: config.fromName,
                isMock: config?.isMock,
                environmentId: queryParams.environmentId,
                configId: queryParams?.configId ? queryParams.configId : config.deployment,
                configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
                imageName: imageString,
            }).then((data: { [key: string]: any }) => {
                setImage(data)
            })
        }
    }, [])

    // ToggleCard with image (From fundle bundle)
    if (imageString) {
        return (
            <div
                className={
                    styles.twMerge(
                        styles?.ui?.ToggleCard?.gridDiv?.mobile,
                        styles?.ui?.ToggleCard?.gridDiv?.tablet,
                        styles?.ui?.ToggleCard?.gridDiv?.desktop
                    ) +
                    ' ' +
                    (checked
                        ? styles.twMerge(
                              styles?.ui?.ToggleCard?.divSelected?.mobile,
                              styles?.ui?.ToggleCard?.divSelected?.tablet,
                              styles?.ui?.ToggleCard?.divSelected?.desktop
                          )
                        : styles.twMerge(
                              styles?.ui?.ToggleCard?.divNotSelected?.mobile,
                              styles?.ui?.ToggleCard?.divNotSelected?.tablet,
                              styles?.ui?.ToggleCard?.divNotSelected?.desktop
                          ))
                }
            >
                <div className="flex items-center justify-center">
                    <Switch onCheck={onCheck} bgCheck={setChecked} />
                </div>
                <div className="flex items-center">
                    <img className="max-h-[35px] w-auto mb-[8px]" src={image.url} alt={image.alt} />
                </div>
                <ConditionalRender condition={description}>
                    <div />
                    <div
                        className={styles.twMerge(
                            styles?.ui?.ToggleCard?.top?.mobile,
                            styles?.ui?.ToggleCard?.top?.tablet,
                            styles?.ui?.ToggleCard?.top?.desktop
                        )}
                    >
                        <div
                            className={styles.twMerge(
                                styles?.ui?.ToggleCard?.descriptionContainer?.mobile,
                                styles?.ui?.ToggleCard?.descriptionContainer?.tablet,
                                styles?.ui?.ToggleCard?.descriptionContainer?.desktop
                            )}
                        >
                            <BodyText2>
                                <StyleCopy>{description}</StyleCopy>
                            </BodyText2>
                            <ConditionalRender condition={infoPopup}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        popupDispatch({
                                            type: 'ADD_POPUP',
                                            payload: {
                                                name: infoPopup,
                                                componentToRender: infoPopup,
                                            },
                                        })
                                    }}
                                    className={
                                        styles.twMerge(
                                            styles?.ui?.Checkbox?.infoButton?.mobile,
                                            styles?.ui?.Checkbox?.infoButton?.tablet,
                                            styles?.ui?.Checkbox?.infoButton?.desktop
                                        ) + ' ml-[4px]'
                                    }
                                >
                                    {' '}
                                    ⓘ
                                </button>
                            </ConditionalRender>
                        </div>
                    </div>
                </ConditionalRender>
            </div>
        )
    } else {
        return (
            <div
                className={
                    styles.twMerge(
                        styles?.ui?.ToggleCard?.div?.mobile,
                        styles?.ui?.ToggleCard?.div?.tablet,
                        styles?.ui?.ToggleCard?.div?.desktop
                    ) +
                    ' ' +
                    (checked
                        ? styles.twMerge(
                              styles?.ui?.ToggleCard?.divSelected?.mobile,
                              styles?.ui?.ToggleCard?.divSelected?.tablet,
                              styles?.ui?.ToggleCard?.divSelected?.desktop
                          )
                        : styles.twMerge(
                              styles?.ui?.ToggleCard?.divNotSelected?.mobile,
                              styles?.ui?.ToggleCard?.divNotSelected?.tablet,
                              styles?.ui?.ToggleCard?.divNotSelected?.desktop
                          )) +
                    (toggleLeft ? ' flex-row-reverse' : '')
                }
            >
                <div>
                    <TitleText2>
                        <StyleCopy>{title}</StyleCopy>
                    </TitleText2>
                    <ConditionalRender condition={description}>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.ToggleCard?.descriptionContainer?.mobile,
                                styles?.ui?.ToggleCard?.descriptionContainer?.tablet,
                                styles?.ui?.ToggleCard?.descriptionContainer?.desktop
                            )}
                        >
                            <BodyText4>
                                <StyleCopy
                                    className={styles.twMerge(
                                        styles?.ui?.descriptionBlue?.switch?.mobile,
                                        styles?.ui?.descriptionBlue?.switch?.tablet,
                                        styles?.ui?.descriptionBlue?.switch?.desktop
                                    )}
                                >
                                    {description}
                                </StyleCopy>
                            </BodyText4>
                            <ConditionalRender condition={infoPopup}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        popupDispatch({
                                            type: 'ADD_POPUP',
                                            payload: {
                                                name: infoPopup,
                                                componentToRender: infoPopup,
                                            },
                                        })
                                    }}
                                    className={
                                        styles.twMerge(
                                            styles?.ui?.Checkbox?.infoButton?.mobile,
                                            styles?.ui?.Checkbox?.infoButton?.tablet,
                                            styles?.ui?.Checkbox?.infoButton?.desktop
                                        ) + ' ml-[4px]'
                                    }
                                >
                                    {' '}
                                    ⓘ
                                </button>
                            </ConditionalRender>
                        </div>
                    </ConditionalRender>
                </div>
                <Switch onCheck={onCheck} bgCheck={setChecked} />
            </div>
        )
    }
}

ToggleCard.defaultProps = {
    title: '',
    description: '',
    onCheck: () => {},
    toggleLeft: false,
    infoPopup: '',
    imageString: '',
}

export default ToggleCard
