/* eslint-disable react/require-default-props */
// Packages
import React, { useContext } from 'react'
// Components
import ConditionalRender from '../components/ConditionalRender'
import LoadingBar from './LoadingBar'
// Contexts
import { ConfigContext } from '../contexts/Config'

function Loading({ text = '', useLoadingBar = false }: { text?: string; useLoadingBar?: boolean }) {
    const [config]: any = useContext(ConfigContext)

    const styles: { [key: string]: { blocker: string; content: string; loading: string; headingText: string } } = {
        freeconnect: {
            blocker: 'fixed z-100 bg-[#0022f0] w-screen h-screen flex flex-col justify-center',
            content: 'pb-16 flex flex-col items-center',
            loading: 'block fc-loading-image h-32 md:h-40 w-full bg-no-repeat bg-contain bg-center',
            headingText: 'font-sofia-pro text-center text-xl font-600 text-white md:text-2xl pt-6',
        },
        easyconnect: {
            blocker: 'fixed z-100 bg-[#06c269] w-screen h-screen flex flex-col justify-center',
            content: 'pb-16 flex flex-col items-center',
            loading: 'block ec-loading-image h-32 md:h-40 w-full bg-no-repeat bg-contain bg-center',
            headingText: 'font-sofia-pro text-center text-xl font-600 text-white md:text-2xl pt-6',
        },
        default: {
            blocker: 'fixed z-100 bg-stone-300 w-screen h-screen flex flex-col justify-center',
            content: '',
            loading: 'text-white loading loading-spinner loading-xl mx-auto',
            headingText: 'text-xl font-600 text-black md:text-2xl',
        },
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <div className={styles?.[config?.tenant] ? styles[config.tenant].blocker : styles.default.blocker}>
            <div className={styles?.[config?.tenant] ? styles[config.tenant].content : styles.default.content}>
                <span className={styles?.[config?.tenant] ? styles[config.tenant].loading : styles.default.loading} />
                <ConditionalRender condition={text}>
                    <h1
                        className={
                            styles?.[config?.tenant] ? styles[config.tenant].headingText : styles.default.headingText
                        }
                    >
                        {text}
                    </h1>
                </ConditionalRender>
                <ConditionalRender condition={useLoadingBar}>
                    <div className="w-full flex items-center mt-[20px] w-[266px]">
                        <LoadingBar />
                    </div>
                </ConditionalRender>
            </div>
        </div>
    )
}

export default Loading
