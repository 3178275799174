/* eslint-disable react/prop-types */
// Packages
import React, { useEffect, useState, useContext } from 'react'

// Contexts
import { FormDataContext } from '../contexts/FormData'
import { CopyContext } from '../contexts/Copy'
import { StylesContext } from '../contexts/Styles'

function LoadingBar() {
    // State
    const [progress, setProgress] = useState(0)

    // Context
    const [formData]: any = useContext(FormDataContext)
    const [copy]: any = useContext(CopyContext)
    const [styles]: any = useContext(StylesContext)

    useEffect(() => {
        // If company has highspeedOffers loading will be slower
        // because an API will check the zip code
        const icrement = copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
            ?.highSpeedOffers
            ? 85
            : 100
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 85) {
                    // Percentage to stop the loading bar
                    clearInterval(interval)
                    return prevProgress
                }
                const progressPercent = prevProgress + icrement / 3000 // 30 seconds, 100% filled (The next progress percentage of the bar)
                return progressPercent
            })
        }, 10)

        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]?.highSpeedOffers) {
            if (formData?.topServiceableCompany && 'highspeedElegible' in formData) {
                setProgress(100)
            }
        } else if (formData?.topServiceableCompany) {
            setProgress(100)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.topServiceableCompany, formData?.highspeedElegible])

    return (
        <div
            className={styles.twMerge(
                styles?.ui?.LoadingBar?.container?.mobile,
                styles?.ui?.LoadingBar?.container?.tablet,
                styles?.ui?.LoadingBar?.container?.desktop
            )}
        >
            <div
                className={styles.twMerge(
                    styles?.ui?.LoadingBar?.inner?.mobile,
                    styles?.ui?.LoadingBar?.inner?.tablet,
                    styles?.ui?.LoadingBar?.inner?.desktop
                )}
                style={{ width: `${progress}%` }}
            />
        </div>
    )
}

export default LoadingBar
